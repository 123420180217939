import {backendErrors} from "@/constants/backend-errors";

function FilterToPath(filter) {
    let str = "";

    for (let key in filter) {
        if (!filter[key]) {
            continue
        }

        if (str !== "") {
            str += "&";
        }

        str += key + "=" + encodeURIComponent(filter[key]);
    }

    return str ? "?" + str : ""
}

function ParseBackendErr(err) {
    if (!err.response || !Array.isArray(err.response.data.details)) {
        console.error("other backend error:", err)

        return {}
    }

    let obj = {}

    err.response.data.details.forEach((val) => {
        const data = backendErrors[val.code]

        if (!data) {
            return
        }

        obj[data.field] = data.description
    })

    return obj
}

export {FilterToPath, ParseBackendErr}
