<template>
  <button v-bind="$attrs"
          class="sv-button"
          :class="[
            { [`sv-button--primary`] : primary && !danger && !warn && !success && !dark },
            { [`sv-button--danger`] : !primary && danger && !warn && !success && !dark },
            { [`sv-button--warn`] : !primary && !danger && warn && !success && !dark },
            { [`sv-button--success`] : !primary && !danger && !warn && success && !dark },
            { [`sv-button--dark`] : !primary && !danger && !warn && !success && dark },
            { [`sv-button--base`] : !primary && !danger && !warn && !success && !dark },

            { ['sv-button--border'] : border },
            { [`sv-button--default`] : !border } ]"
          @click="$emit('click', $event)">
    <span class="sv-button__content">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  emits: [
    "click"
  ],
  name: "SvButton",
  props: {
    primary: {type: Boolean, default: false},
    danger: {type: Boolean, default: false},
    warn: {type: Boolean, default: false},
    success: {type: Boolean, default: false},
    dark: {type: Boolean, default: false},

    border: {type: Boolean, default: false},
  }
}
</script>

<style lang="scss" scoped>
.sv-button {
  border: 0;
  margin: 5px;
  border-radius: 12px;
  transition: all .25s ease;
  position: relative;
  user-select: none;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  font-size: .8rem;
  box-sizing: border-box;

  &__content {
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--border {
    background: rgba(var(--sv-color), 0);
    color: rgba(var(--sv-color), 1);

    &:before {
      content: '';
      border: 2px solid rgba(var(--sv-color), 1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: transparent;
      pointer-events: none;
      transition: all .25s ease;
      box-sizing: border-box;
    }

    &:hover,
    &:focus {
      color: #fff;
      background: rgba(var(--sv-color), 1);

      &:before {
        border: 2px solid rgba(var(--sv-color), .5);
      }
    }
  }

  &--default {
    background: rgba(var(--sv-color), 1);
    color: #fff;

    &:hover,
    &:focus {
      background: rgba(var(--sv-color), .9);
    }
  }
}

</style>

