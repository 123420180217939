<template>
  <div class="scroll-trigger" ref="scrollTrigger" id="scroll-trigger"/>
</template>

<script>
export default {
  name: "SvScrollTrigger",
  emits: [
    "triggered"
  ],
  data() {
    return {
      observer: undefined
    }
  },
  methods: {
    init() {
      this.observer.observe(this.$refs.scrollTrigger)
    }
  },
  mounted() {
    this.observer = new IntersectionObserver( (entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          this.$emit("triggered")
        }
      })
    })

    this.init()
  },
  unmounted() {
    this.observer.disconnect()
  }
}
</script>
<style lang="scss" scoped>
.scroll-trigger {
  margin-top: -10px;
}
</style>