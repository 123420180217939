const backendErrors = {
    // auth errors.
    200000: {
        namespace: 'auth',
        field: 'login',
        description: 'Введите логин',
    },
    200001: {
        namespace: 'auth',
        field: 'password',
        description: 'Введите пароль',
    },
    200002: {
        namespace: 'auth',
        field: 'credentials',
        description: 'Неверный логин или пароль',
    },
    200003: {
        namespace: 'auth',
        field: 'token',
        description: 'Expired token',
    },
    200004: {
        namespace: 'auth',
        field: 'token',
        description: 'Invalid token',
    },
    200005: {
        namespace: 'auth',
        field: 'secret',
        description: 'Введите секретный код',
    },
    200006: {
        namespace: 'auth',
        field: 'secret',
        description: 'Неверный секретный код',
    },
    200007: {
        namespace: 'auth',
        field: 'password',
        description: 'Введите текущий пароль',
    },
    200008: {
        namespace: 'auth',
        field: 'newPassword',
        description: 'Введите новый пароль',
    },
    200009: {
        namespace: 'auth',
        field: 'newPassword',
        description: 'Пароль должен быть не менее 8 символов',
    },
    200010: {
        namespace: 'auth',
        field: 'newPassword',
        description: 'Пароль должен отличаться от старого',
    },
    200011: {
        namespace: 'auth',
        field: 'password',
        description: 'Неверный пароль',
    },
    200012: {
        namespace: 'auth',
        field: 'code',
        description: 'Введите код',
    },
    200013: {
        namespace: 'auth',
        field: 'code',
        description: 'Код должен состоять из 6 символов',
    },
    200014: {
        namespace: 'auth',
        field: 'code',
        description: 'Неверный код',
    },

    // school errors.
    201000: {
        namespace: 'school',
        field: 'name',
        description: 'Укажите название',
    },
    201001: {
        namespace: 'school',
        field: 'city',
        description: 'Укажите город',
    },
    201002: {
        namespace: 'school',
        field: 'addr',
        description: 'Укажите адрес',
    },

    // teacher errors.
    202000: {
        namespace: 'teacher',
        field: 'schoolID',
        description: 'Укажите школу',
    },
    202001: {
        namespace: 'teacher',
        field: 'firstName',
        description: 'Укажите имя',
    },
    202002: {
        namespace: 'teacher',
        field: 'lastName',
        description: 'Укажите фамилию',
    },
    202003: {
        namespace: 'teacher',
        field: 'phone',
        description: 'Укажите телефон',
    },
    202004: {
        namespace: 'teacher',
        field: 'email',
        description: 'Неверный формат email',
    },

    // book errors.
    300000: {
        namespace: 'book',
        field: 'name',
        description: 'Укажите название',
    },

    // order errors.
    400000: {
        namespace: 'order',
        field: 'id',
        description: 'Укажите id',
    },
    400001: {
        namespace: 'order',
        field: 'schoolID',
        description: 'Выберите школу',
    },
    400002: {
        namespace: 'order',
        field: 'teacherID',
        description: 'Выберите учителя',
    },
    400003: {
        namespace: 'order',
        description: 'OrderBookIDRequired',
    },
    // 400004: {
    //     namespace: 'order',
    //     field: 'books.count',
    //     composite: true,
    //     description: 'Укажите количество',
    // },
    400005: {
        namespace: 'order',
        description: 'OrderStateRequired',
    },
    400006: {
        namespace: 'order',
        description: 'OrderNewStateInvalid',
    }
}

export {backendErrors}
