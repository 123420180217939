<template>
  <div class="select select--primary" v-click-outside="closeGroup">
    <!-- Input -->
    <div class="select-input">
      <div class="select-input-content">
        <input ref="input"
               :class="error ? 'select-input-error-border' : ''"
               :disabled="disabled"
               :placeholder="placeholder"
               :value="input"
               autocomplete="off"
               class="select-input-content-inner"
               type="text"
               @click="openGroup"
               @focus="handleFocus"
               @input="handleInput($event.target.value)"
               @keydown.up="handleKeydownUp"
               @keydown.down="handleKeydownDown"
               @keydown.enter="handleKeydownEnter"
               @keydown.esc="handleKeydownEscape"/>

        <label v-if="label"
               class="select-input-content-label"
               @click="$refs.input.focus()">{{ label }}
        </label>

        <!-- Icons -->
        <i v-if="clearable && input && !disabled"
           class="select-input-content-suffix close sv-icon-close"
           @click="handleClear()"/>

        <i v-else-if="loading"
           class="select-input-content-suffix loading sv-icon-loading"
           @click="$refs.input.focus()"/>

        <i v-else-if="!disabled"
           :class="group.isActive ? 'up' : ''"
           class="select-input-content-suffix arrow sv-icon-arrow"
           @click="$refs.input.focus()"/>
      </div>

      <div v-if="error" class="select-input-error">{{ error }}</div>
    </div>

    <!-- Select options group -->
    <div
        v-if="group.isActive && !disabled"
        :style="`min-width: ${group.width}px`"
        class="select-options">
      <div class="select-options-arrow"/>

      <div class="select-options-content" ref="options">
        <div v-if="group.filteredCount === 0 || options.length === 0"
             class="select-options-content-no-data">Ничего не найдено
        </div>

        <SvSelectOption
            v-for="(option, idx) in preparedOptions"
            :key="idx"
            :hidden="option.hidden"
            :label="optionLabel(option.value)"
            :selected="option.selected"
            :hovered="option.hovered"
            :value="option.value"
            @click="selectOption(option.value)"/>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "@/directives/click-outside";
import SvSelectOption from "@/components/default/select/SvSelectOption";

export default {
  name: "SvSelect",
  components: {
    SvSelectOption,
  },
  emits: [
    "update:modelValue",
    "change",
    "focus",
    "input",
  ],
  directives: {
    ClickOutside: ClickOutside,
  },
  props: {
    modelValue: {},
    valueKey: {type: String, default: ""},
    error: {type: String, default: ""},
    multiple: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    filter: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    placeholder: {type: String, default: ""},
    label: {type: String, default: ""},
    type: {type: String, default: "text"},
    options: {type: Array, default: Array}
  },
  data() {
    return {
      input: this.optionLabel(this.modelValue),
      group: {
        isActive: false,
        width: 0,
        filter: "",
        filteredCount: 0,
        hoveredItem: null,
      },
    }
  },
  methods: {
    focus: function () {
      this.$refs.input.focus()
    },

    openGroup() {
      this.group.isActive = true
    },
    closeGroup() {
      this.group.isActive = false
    },

    handleInput(evt) {
      if (this.disabled) {
        return;
      }

      if (this.filter) {
        this.group.filter = evt
      }

      this.group.hoveredItem = null
      this.group.isActive = true
      this.input = evt
      this.$emit("input", evt)
    },
    handleKeydownEscape(evt) {
      evt.preventDefault()
      this.group.isActive = false
    },
    handleKeydownUp() {
      if (this.group.hoveredItem === null || this.group.hoveredItem === 0) {
        this.group.hoveredItem = this.options.length - 1

        return
      }

      this.group.hoveredItem--
    },
    handleKeydownDown() {
      if (this.group.hoveredItem === null || this.group.hoveredItem === this.options.length) {
        this.group.hoveredItem = 0

        return
      }

      this.group.hoveredItem++
    },
    handleKeydownEnter() {
      if (!this.group.hoveredItem) {
        return
      }

      this.selectOption(this.options[this.group.hoveredItem])
      this.group.hoveredItem = null
    },
    handleClear() {
      this.group.filter = ""
      this.$emit("update:modelValue", null)
      this.$emit("change", null)
      this.input = ""
    },
    handleFocus(evt) {
      this.openGroup()

      this.$emit("focus", evt)
    },

    optionLabel(value) {
      if (!value) {
        return ''
      }

      return this.valueKey ? value[this.valueKey] || '' : value
    },
    resize() {
      this.group.width = this.$refs.input.clientWidth
    },
    selectOption(option) {
      this.group.isActive = false

      if (this.filter) {
        this.group.filter = ""
      }

      this.input = this.optionLabel(option)
      this.$emit("update:modelValue", option)
      this.$emit("change", option)
    },
    isSelected(option) {
      if (!this.multiple) {
        return this.modelValue === option
      }

      return false
    },
    setFilteredCount(count) {
      this.group.filteredCount = count
    },
  },
  computed: {
    preparedOptions() {
      const targetValue = this.group.filter.toLowerCase()

      let options = []

      this.options.forEach((value, idx) => {
        const optionValue = this.optionLabel(value).toLowerCase()

        const hidden = typeof optionValue === 'number' ?
            optionValue !== targetValue :
            optionValue.indexOf(targetValue) === -1

        if (hidden) {
          return
        }

        options.push({
          value: value,
          hovered: this.group.hoveredItem === idx,
          selected: this.isSelected(value),
        })
      })

      this.setFilteredCount(options.length)

      return options
    }
  },
  watch: {
    modelValue(newValue) {
      this.input = this.optionLabel(newValue)
    }
  },
  mounted() {
    this.resize()
    window.addEventListener("resize", this.resize)
  },
  unmounted() {
    window.removeEventListener("resize", this.resize)
  }
}
</script>

<style lang="scss" scoped>
.select {
  height: 100%;

  &-input {
    position: relative;
    background-image: none;
    height: 100%;
    width: 100%;

    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      height: 100%;
      width: 100%;

      &-label {
        position: absolute;
        left: 10px;
        cursor: text;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translate(-2px, -85%);
        font-size: 0.85rem;
      }

      &-inner {
        position: relative;
        padding: 7px 13px 7px 10px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        border-radius: 10px;
        color: rgba(var(--sv-text), 1);
        border: 1px solid var(--sovunyaGray90);
        -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
      }

      &-inner:hover {
        border: 1px solid var(--sovunyaGray80);
      }

      &-inner:focus {
        border: 1px solid rgba(var(--sv-primary), .9);
      }

      &-suffix {
        right: 10px;
        position: absolute;
        cursor: pointer;
        opacity: 0.6;

        &:hover {
          opacity: 1;
          color: rgba(var(--sv-primary), 0.9) !important;
        }

        &.arrow {
          margin-top: -2px;
          width: 7px;
          height: 7px;
        }

        &.close {
          width: 10px;
          height: 10px;
        }

        &.loading {
          width: 20px;
          height: 20px;
          opacity: 1;
        }
      }

      ::placeholder {
        color: var(--sovunyaGray80);
      }
    }

    &-error {
      color: rgb(var(--sv-danger));
      padding-top: 5px;
      top: 100%;
      margin-left: 8px;
      height: .85rem;
      font-size: .85rem;
    }

    &-error-border {
      border: 1px solid rgb(var(--sv-danger));
    }
  }

  &-options {
    margin-top: 15px;
    max-height: 274px;
    position: absolute;
    display: block;
    z-index: 5;
    background: var(--sv-theme-layout);
    border: 1px solid var(--sovunyaGray90);
    border-radius: 10px;
    -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    padding: 5px;

    &-content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      max-height: 200px;
      overflow: auto;
      height: auto;
      z-index: 100;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all .25s ease;
      transition: all .25s ease;
      position: relative;
      scroll-behavior: smooth;

      &-no-data {
        font-size: .9rem;
        text-align: center;
        padding: 6px 10px;
      }

      &-option {
        border: 0;
        width: 100%;
        height: 50px;
        padding: 6px 10px;
        text-align: left;
        background: transparent;
        -webkit-transition: all .25s ease;
        transition: all .25s ease;
        margin: 2px 0;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        opacity: 1;
        visibility: visible;
        max-height: 40px;
        cursor: pointer;
        color: rgba(var(--sv-text), 1);

        &.selected {
          color: rgba(var(--sv-color), 1);
          background: rgba(var(--sv-color), .1);
          border-radius: 10px;
        }

        &:hover:not(.selected) {
          padding-left: 14px;
          color: rgba(var(--sv-color), 1);
          background: rgb(var(--sv-gray-1));
        }
      }
    }

    &-arrow {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      left: 50%;
      top: -6px;
      position: absolute;
      width: 10px;
      height: 10px;
      border-left: 1px solid var(--sovunyaGray90);
      border-top: 1px solid var(--sovunyaGray90);
      background: var(--sv-theme-layout);
    }
  }
}
</style>
