<template>
  <div class="card">
    <slot class="card-header" name="header"></slot>

    <slot class="card-title" name="title"></slot>

    <slot class="card-content" name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "SvCard"
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: var(--sv-theme-layout);
  border: 1px solid rgba(228, 228, 228, 0.6);
  box-sizing: border-box;
  box-shadow: 0 8px 8px 0 rgb(0 0 0 / 10%);
  border-radius: 10px;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 15px;
    width: 100%;
    border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
  }

  &-title {
    width: 100%;
    font-style: normal;
    font-weight: 900;
    font-size: 1.3rem;
    padding: 10px 16px;
    border-bottom: 0.5px solid rgba(228, 228, 228, 0.6);
    opacity: .85;
    cursor: pointer;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}
</style>