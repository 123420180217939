<template>
  <div class="input">
    <div class="input-content">
      <input
          ref="input"
          :class="error ? 'input-error-border' : ''"
          :placeholder="placeholder"
          :type="inputType"
          :value="modelValue"
          autocomplete="off"
          class="input-content-inner"
          @focus="focusHandler"
          @input="inputHandler"/>

      <label v-if="label"
             class="input-content-label"
             @click="$refs.input.focus()">{{ label }}
      </label>

      <!-- Icons -->
      <i v-if="type === 'password'"
         class="input-content-suffix el-icon-view"
         @click="inputType = inputType === 'password' ? 'text' : 'password'"/>

      <i v-else-if="loading"
         class="input-content-suffix loading sv-icon-loading"
         @click="$refs.input.focus()"/>
    </div>

    <div v-if="error" class="input-error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: "SvInput",
  emits: [
    "update:modelValue",
    "input",
    "focus",
  ],
  props: {
    modelValue: {type: [String, Number], default: ''},
    error: {type: String, default: ""},
    placeholder: {type: String, default: ""},
    label: {type: String, default: ""},
    type: {type: String, default: "text"},
    loading: {type: Boolean, default: false},
  },
  data() {
    return {
      inputType: this.type
    }
  },
  methods: {
    focus: function () {
      this.$refs.input.focus()
    },

    inputHandler(evt) {
      this.$emit("update:modelValue", evt.target.value)
      this.$emit("input", evt.target.value)
    },
    focusHandler(evt) {
      this.$emit("focus", evt)
    },
  }
}
</script>

<style lang="scss" scoped>
.input {
  position: relative;
  background-image: none;
  height: 100%;
  width: 100%;

  &-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 100%;
    width: 100%;

    &-label {
      position: absolute;
      left: 10px;
      cursor: text;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translate(-2px, -85%);
      font-size: 0.85rem;
    }

    &-inner {
      position: relative;
      padding: 7px 13px 7px 10px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      color: rgba(var(--sv-text), 1);
      border: 1px solid var(--sovunyaGray90);
      -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
      transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    }

    &-inner:hover {
      border: 1px solid var(--sovunyaGray80);
    }

    &-inner:focus {
      border: 1px solid rgba(var(--sv-primary), .6);
    }

    &-suffix {
      right: 10px;
      position: absolute;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
        color: rgba(var(--sv-primary), 0.9);
      }

      &.loading {
        width: 20px;
        height: 20px;
        opacity: 1;
      }
    }

    ::placeholder {
      color: var(--sovunyaGray80);
    }
  }

  &-error {
    color: rgb(var(--sv-danger));
    padding-top: 5px;
    top: 100%;
    margin-left: 8px;
    height: .85rem;
    font-size: .85rem;
  }

  &-error-border {
    border: 1px solid rgb(var(--sv-danger));
  }
}
</style>
