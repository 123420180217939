<template>
  <button
      ref="option"
      class="select-option"
      :class="[
          {['selected'] : selected},
          {['hovered'] : hovered},
      ]"
      :style="`display: ${hidden ? 'none' : 'flex'}`">{{ label }}
  </button>
</template>

<script>
export default {
  name: "SvSelectOption",
  props: {
    label: {required: true},
    value: {required: true},
    selected: {type: Boolean, default: false},
    hidden: {type: Boolean, default: false},
    hovered: {type: Boolean, default: false,}
  },
}
</script>

<style lang="scss" scoped>
.select-option {
  border: 0;
  width: 100%;
  height: 50px;
  padding: 6px 10px;
  text-align: left;
  background: transparent;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  margin: 2px 0;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  opacity: 1;
  visibility: visible;
  max-height: 40px;
  cursor: pointer;
  color: rgba(var(--sv-text), 1);

  &.selected {
    color: rgba(var(--sv-color), 1);
    background: rgba(var(--sv-color), .1);
    border-radius: 10px;
  }

  &.hovered:not(.selected) {
    padding-left: 14px;
    color: rgba(var(--sv-color), 1);
    background: rgb(var(--sv-gray-1));
  }

  &:hover:not(.selected) {
    padding-left: 14px;
    color: rgba(var(--sv-color), 1);
    background: rgb(var(--sv-gray-1));
  }
}
</style>
